
import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {
  addPatientPayer,
  deletePatientPayer,
  updatePatientPayer,
} from "@/api/patientPayer.api";
import useVuelidate from "@vuelidate/core";
import { helpers,required } from "@vuelidate/validators";
import Multiselect from "@vueform/multiselect";
import { useStore } from "vuex";
import { BaseDictionary } from "../dictionaries/baseDictionary.model";
import { PatientInsurance, PatientPayer } from "./patientPayer.model";
import { searchDictInsuranceCompanys } from "@/api/code-master-insurance-company.api";
import { EncounterPanel } from "../panel/panel.model";
import { getPanel } from "@/api/panel.api";
import { getDictionary } from "@/api/dictionaries.api";

export interface Data {
  items: [];
  patientPayer?: PatientPayer;
  patientRelationshipToSubscriber: BaseDictionary[];
  updateOnly: boolean;
  createNewMode: boolean;
  panel: EncounterPanel;
  payer: { isLoading: boolean; store: PatientInsurance[] };
}

export default defineComponent({
  name: "PatientPayerComponent",
  components: { Multiselect },
  props: ["patientPayer", "updateOnly", "isNormal"],
  setup(props, ctx) {
    const store = useStore();

    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      items: [],
      patientRelationshipToSubscriber: [],
      updateOnly: false,
      patientPayer: undefined,
      createNewMode: true,
      panel: { facilities: [], patients: [], providers: [] },
      payer: { isLoading: false, store: [] },
    });

    data.patientPayer = {
      id: "",
      name: "",
      payerId: "",
      subscriberFirstName: "",
      subscriberMiddleInitial: "",
      subscriberLastName: "",
      patientRelationshipToSubscriber: "18",
      subscriberIdNumber: "",
      subscriberGroupNumber: "",
      patientId: "",
    };

    watch(
      () => props.patientPayer,
      () => {
        if (props.patientPayer) {
          data.patientPayer = props.patientPayer as any;
          data.createNewMode = false;
        }
      }
    );

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.panel = await getPanel(true);
      await getData();

      if (props.patientPayer) {
        data.patientPayer = props.patientPayer as any;
        data.createNewMode = false;
      }
      if (props.updateOnly) {
        data.updateOnly = props.updateOnly;
      }

      data.patientRelationshipToSubscriber = await getDictionary(10, "");
      relationshipChanged();
    });

    async function switchMode() {
      data.createNewMode = true;
      data.patientPayer = {
        id: "",
        effectiveDate: new Date(),
        name: "",
        payerId: "",
        termDate: new Date(),
        subscriberFirstName: "",
        subscriberMiddleInitial: "",
        subscriberLastName: "",
        patientRelationshipToSubscriber: "18",
        subscriberIdNumber: "",
        subscriberGroupNumber: "",
        patientId: "",
      };
      ctx.emit("addMode");
    }

    function relationshipChanged() {
      if (data.patientPayer) {
        if (data.patientPayer?.patientRelationshipToSubscriber == "18") {
          const patient = data.panel.patients.find((item) => {
            return item.id == data.patientPayer?.patientId;
          });
          if (patient) {
            data.patientPayer.subscriberFirstName = patient.firstName;
            data.patientPayer.subscriberMiddleInitial = patient.middleName;
            data.patientPayer.subscriberLastName = patient.lastName;
          }
        } else {
          data.patientPayer.subscriberFirstName = "";
          data.patientPayer.subscriberMiddleInitial = "";
          data.patientPayer.subscriberLastName = "";
        }
      }
    }

    async function addItem() {
      const result = await v$.value.$validate();
      if (result) {
        await addPatientPayer(data.patientPayer);
        data.createNewMode = false;
      }
      ctx.emit("itemChanged");
    }

    async function updateItem() {
      const result = await v$.value.patientPayer.$validate();
      if (result) {
        await updatePatientPayer(data.patientPayer);
      }
    }

    async function deleteItem() {
      await deletePatientPayer(data.patientPayer);
      ctx.emit("itemChanged");
      ctx.emit("switchMode");
    }

    async function getData() {
      data.payer.isLoading = true;
      data.payer.store = await searchDictInsuranceCompanys({ search: "" });
      data.payer.isLoading = false;
    }

    function payerIdChanged(payerId: string) {
      if (data.patientPayer) {
        const item = data.payer.store.find((dict) => {
          return dict.payerId == payerId;
        });
        if (item) {
          data.patientPayer.name = item.name;
        }
      }
    }
    function payerNameChanged(payerName: string) {
      if (data.patientPayer) {
        const item = data.payer.store.find((dict) => {
          return dict.name == payerName;
        });
        if (item) {
          data.patientPayer.payerId = item.payerId;
        }
      }
    }

    const providerRules = {
      patientPayer: {
        name: { required:helpers.withMessage("Required", required) },
        payerId: { required:helpers.withMessage("Required", required) },
        //effectiveDate: { required },
        //termDate: { required },
        releaseOfInformation: { required:helpers.withMessage("Required", required) },
        assignmentOfBenefits: { required:helpers.withMessage("Required", required) },
        patientRelationshipToSubscriber: { required:helpers.withMessage("Required", required) },
        patientId: { required:helpers.withMessage("Required", required) },
        subscriberFirstName: { required:helpers.withMessage("Required", required) },
        subscriberLastName: { required:helpers.withMessage("Required", required) },
        subscriberIdNumber: { required:helpers.withMessage("Required", required) },
      },
    };

    const v$ = useVuelidate(providerRules, data as never);

    return {
      organizationId,
      data,
      addItem,
      updateItem,
      deleteItem,
      switchMode,
      getData,
      payerIdChanged,
      payerNameChanged,
      relationshipChanged,
      v$,
    };
  },
});
